<template>
    <div>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
<!--                    <v-sheet color="grey lighten-2">-->
<!--                        <v-toolbar color="grey lighten-2" dense flat>-->
<!--                            <div class="d-flex flex-row">-->
<!--                                <div class="font-sm">-->
<!--                                    <v-icon small color="black" class="mr-2">fas fa-code-branch</v-icon>{{ $t('message.contractSplit') }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-tooltip bottom>-->
<!--                                <template v-slot:activator="{ on }">-->
<!--                                    <v-switch-->
<!--                                        :label="$t('message.boardView')"-->
<!--                                        dense-->
<!--                                        flat-->
<!--                                        hide-details-->
<!--                                        v-model="boardMode"-->
<!--                                        v-on="on"-->
<!--                                    ></v-switch>-->
<!--                                </template>-->
<!--                                <span>{{ $t('message.switchToBoardView') }}</span>-->
<!--                            </v-tooltip>-->
<!--                            <ExportTable-->
<!--                                :cols-to-delete="{start_col: 15, ncols: 1}"-->
<!--                                :has-add-function="false"-->
<!--                                file-name="shipments"-->
<!--                                table-id="shipmentTable"-->
<!--                            >-->
<!--                            </ExportTable>-->
<!--                        </v-toolbar>-->
<!--                    </v-sheet>-->
<!--                    <ShipmentsBoard v-if="boardMode"></ShipmentsBoard>-->
<!--                    <ShipmentsListing v-else></ShipmentsListing>-->
                    <v-tabs
                        v-model="shippingOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="shp" @click="changeExportParams(0)">{{ $t('message.shipments') }}</v-tab>
                        <v-tab key="pl" @click="changeExportParams(1)">{{ $t('message.packingLists') }}</v-tab>
                        <v-tab key="si" @click="changeExportParams(2)">{{ $t('message.si') }}</v-tab>
                        <v-tab key="lca" @click="changeExportParams(3)">{{ $t('message.lcas') }}</v-tab>
                        <v-tab key="ipa" @click="changeExportParams(4)">{{ $t('message.ipas') }}</v-tab>
<!--                        <v-tab key="cn" @click="changeExportParams(5)">{{ $t('message.coverNotes') }}</v-tab>-->
                    </v-tabs>
                    <v-tabs-items v-model="shippingOverviewCurrentTab">
                        <v-tab-item key="shp-list">
                            <v-card flat>
                                <ShipmentsListingNew></ShipmentsListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="pl-list">
                            <v-card flat>
                                <PackingListsListingNew></PackingListsListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="si-list">
                            <v-card flat>
                                <ShippingInstructionsListingNew></ShippingInstructionsListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="lca-list">
                            <v-card flat>
                                <LcasListingNew></LcasListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ipa-list">
                            <v-card flat>
                                <IpasListingNew></IpasListingNew>
                            </v-card>
                        </v-tab-item>
<!--                        <v-tab-item key="cn-list">-->
<!--                            <v-card flat>-->
<!--                                <CoverNotesListing></CoverNotesListing>-->
<!--                            </v-card>-->
<!--                        </v-tab-item>-->
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import ShipmentsBoard from "../../../components/Appic/ShipmentsBoard";
    // import ShipmentsListing from "../../../components/Appic/ShipmentsListing";
    import {mapFields} from "vuex-map-fields";
    import LoadingComponent from "Components/Appic/LoadingComponent";
    // import ExportTable from "../../../components/Appic/ExportTable";
    // const ShipmentsListing = () => ({
    //     component: import("../../../components/Appic/ShipmentsListing"),
    //     loading: LoadingComponent
    // });
    const ShipmentsListingNew = () => ({
        component: import("../../../components/Appic/ShipmentsListingNew"),
        loading: LoadingComponent
    });
    // const ExportTable = () => import("../../../components/Appic/ExportTable");
    // const ShipmentsBoard = () => import("../../../components/Appic/ShipmentsBoard");
    const PackingListsListingNew = () => ({
        component: import("../../../components/Appic/PackingListsListingNew"),
        loading: LoadingComponent
    });
    const IpasListingNew = () => ({
        component: import("../../../components/Appic/IpasListingNew"),
        loading: LoadingComponent
    });
    const LcasListingNew = () => ({
        component: import("../../../components/Appic/LcasListingNew"),
        loading: LoadingComponent
    });
    const ShippingInstructionsListingNew = () => ({
        component: import("Components/Appic/ShippingInstructionsListingNew"),
        loading: LoadingComponent
    });
    // const CoverNotesListing = () => ({
    //     component: import("Components/Appic/CoverNotesListing"),
    //     loading: LoadingComponent
    // });

    export default {
        name: "ShipmentsOverview",
        components: {
            ShipmentsListingNew,
            // CoverNotesListing,
            // ExportTable,
            IpasListingNew,
            LcasListingNew,
            PackingListsListingNew,
            ShippingInstructionsListingNew
        },
        title: '',
        data() {
            return {
                loader: false,
                active: null,
                colsToDelete: {
                    start_col: 10,
                    ncols: 1
                },
                fileName: 'shipments',
                hasAddFunction: true,
                documentType: 'shp',
                document: 'shipments',
                tableId: 'shipmentsTable'
            }
        },
        computed: {
            ...mapFields('runtime',{
                shippingOverviewCurrentTab: 'shippingOverviewCurrentTab',
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            changeExportParams(documentType) {
                switch (documentType) {
                    case 0:
                        this.colsToDelete = {start_col: 13, ncols: 1}
                        this.fileName = 'packing_lists'
                        this.hasAddFunction = true
                        this.document = 'packinglists'
                        this.documentType = 'pl'
                        this.tableId = 'packingListTable'
                        break;
                    case 1:
                        this.colsToDelete = {start_col: 13, ncols: 1}
                        this.fileName = 'packing_lists'
                        this.hasAddFunction = true
                        this.document = 'packinglists'
                        this.documentType = 'pl'
                        this.tableId = 'packingListTable'
                        break;
                    case 2:
                        this.colsToDelete = {start_col: 6, ncols: 1}
                        this.fileName = 'sdas'
                        this.hasAddFunction = true
                        this.document = 'sdas'
                        this.documentType = 'si'
                        this.tableId = 'shippingInstructionTable'
                        break;
                    case 3:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'lcas'
                        this.hasAddFunction = true
                        this.document = 'lcas'
                        this.documentType = 'lca'
                        this.tableId = 'letterOfCreditAdviceTable'
                        break;
                    case 4:
                        this.colsToDelete = {start_col: 8, ncols: 1}
                        this.fileName = 'ipas'
                        this.hasAddFunction = true
                        this.document = 'ipas'
                        this.documentType = 'ipa'
                        this.tableId = 'importPermitAdviceTable'
                        break;
                    case 5:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'cover_notes'
                        this.hasAddFunction = true
                        this.document = 'covernotes'
                        this.documentType = 'cn'
                        this.tableId = 'coverNoteTable'
                        break;
                }
            }
        },
        created(){
            this.changeExportParams(this.shippingOverviewCurrentTab)
        },
        mounted(){
            this.$title = this.$t('message.titles.shipments')
        }
    }
</script>

<style scoped>

</style>